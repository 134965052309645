var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('div',{staticClass:"ml-2"},[_c('PageTitle',{staticClass:"font-weight-bold ft font-size-md",attrs:{"text":"Branches"}})],1),_c('div',[_c('v-btn',{directives:[{name:"ripple",rawName:"v-ripple",value:({ class: 'primary--text' }),expression:"{ class: 'primary--text' }"}],staticClass:"ml-2 mt-3 white--text ft font-weight-medium",staticStyle:{"font-size":"12px"},attrs:{"color":"primary"},on:{"click":function($event){return _vm.open('details')}}},[_vm._v(" New Branch "),_c('v-icon',{attrs:{"size":"20","color":"white"}},[_vm._v("mdi-plus")])],1),_c('h4',{staticClass:"ft font-weight-medium font-size-sm",staticStyle:{"margin-top":"20px","margin-left":"10px"}},[_vm._v(" All Branches ")])],1),_c('v-row',{staticStyle:{"margin-top":"5px","margin-left":"1px","margin-right":"10px"},attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"12","sm":"12"}},[[_c('div',[_c('v-data-table',{staticClass:"box-shadow-light ft font-weight-medium font-size-md",attrs:{"headers":_vm.headers,"items":_vm.branches,"item-key":"branch","server-items-length":_vm.paginate.total,"options":_vm.options,"loading-text":"Loading... Please wait","loading":_vm.listLoading,"footer-props":{
              itemsPerPageOptions: [10, 20, 30]
            }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.avatar",fn:function(ref){
            var item = ref.item;
return [_c('v-avatar',{staticClass:"ma-1",attrs:{"color":"primary","size":"40"}},[_c('span',{staticClass:" ft white--text font-weight-medium"},[_vm._v(_vm._s(item.name.charAt(0)))])])]}},{key:"item.actions",fn:function(ref){
            var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","link":"","to":{
                      name: 'master.branch-details',
                      params: { id: item.id }
                    },"dark":""}},on),[_c('v-icon',{staticStyle:{"font-size":"14px"},attrs:{"color":"primary"}},[_vm._v("visibility")])],1)]}}],null,true)},[_c('span',{staticClass:"ft font-weight-medium font-size-sm"},[_vm._v("View Branch Details")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"disabled":item.users.length > 0,"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.open('add', 'branches/filterBranchDetails', item.id)}}},on),[_c('v-icon',{staticStyle:{"font-size":"14px"},attrs:{"color":"primary"}},[_vm._v("people")])],1)]}}],null,true)},[_c('span',{staticClass:"ft font-weight-medium font-size-sm"},[_vm._v("View Branch Admin")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){return _vm.open('edit', 'branches/details', item.id)}}},on),[_c('v-icon',{staticStyle:{"font-size":"14px"},attrs:{"color":"primary"}},[_vm._v("mdi-pencil-outline")])],1)]}}],null,true)},[_c('span',{staticClass:"ft font-weight-medium font-size-sm"},[_vm._v("Edit Branch")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","to":{
                      name: 'master.branches.meter-readings',
                      query: { branchId: item.id }
                    }}},on),[_c('v-icon',{staticStyle:{"font-size":"14px"},attrs:{"color":"primary"}},[_vm._v("mdi-lotion-outline")])],1)]}}],null,true)},[_c('span',{staticClass:"ft font-weight-medium font-size-sm"},[_vm._v("Reset Pump Meter Readings")])])]}}])})],1)]],2)],1),_c('SnackBar',{attrs:{"showSnackBar":_vm.showSnackBar,"message":_vm.message,"status":_vm.status}}),_c('AddBranchAdminDialog',{attrs:{"state":_vm.dialog('add'),"loading":_vm.loading},on:{"branchAdmin":_vm.addBranchAdmin,"actions":_vm.close}}),_c('EditBranchDialog',{attrs:{"regions":_vm.regions,"loading":_vm.loading,"state":_vm.dialog('edit')},on:{"branch":_vm.editBranch,"actions":_vm.close}}),_c('AddBranchDialog',{attrs:{"regions":_vm.regions,"state":_vm.dialog('details'),"loading":_vm.loading},on:{"actions":_vm.close,"branch":_vm.addBranch}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }