<template>
  <v-container>
    <div class="ml-2">
      <PageTitle text="Branches" class="font-weight-bold ft font-size-md" />
    </div>
    <div>
      <v-btn
        v-ripple="{ class: 'primary--text' }"
        color="primary"
        class="ml-2 mt-3 white--text ft font-weight-medium"
        style="font-size: 12px"
        @click="open('details')"
      >
        New Branch <v-icon size="20" color="white">mdi-plus</v-icon>
      </v-btn>
      <h4
        style="margin-top: 20px; margin-left: 10px"
        class="ft font-weight-medium font-size-sm"
      >
        All Branches
      </h4>
    </div>
    <v-row
      align="center"
      justify="center"
      style="margin-top: 5px; margin-left:1px; margin-right:10px"
    >
      <v-col cols="12" sm="12">
        <template>
          <div>
            <v-data-table
              :headers="headers"
              :items="branches"
              item-key="branch"
              :server-items-length="paginate.total"
              :options.sync="options"
              loading-text="Loading... Please wait"
              :loading="listLoading"
              :footer-props="{
                itemsPerPageOptions: [10, 20, 30]
              }"
              class="box-shadow-light ft font-weight-medium font-size-md"
            >
              <template #item.avatar="{item}">
                <v-avatar color="primary" size="40" class="ma-1">
                  <span class=" ft white--text font-weight-medium">{{
                    item.name.charAt(0)
                  }}</span>
                </v-avatar>
              </template>
              <template #item.actions="{item}">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      icon
                      link
                      :to="{
                        name: 'master.branch-details',
                        params: { id: item.id }
                      }"
                      dark
                      v-on="on"
                    >
                      <v-icon color="primary" style="font-size: 14px;"
                        >visibility</v-icon
                      >
                    </v-btn>
                  </template>
                  <span class="ft font-weight-medium font-size-sm"
                    >View Branch Details</span
                  >
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      :disabled="item.users.length > 0"
                      icon
                      @click.stop="
                        open('add', 'branches/filterBranchDetails', item.id)
                      "
                      v-on="on"
                    >
                      <v-icon color="primary" style="font-size: 14px;"
                        >people</v-icon
                      >
                    </v-btn>
                  </template>
                  <span class="ft font-weight-medium font-size-sm"
                    >View Branch Admin</span
                  >
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      @click="open('edit', 'branches/details', item.id)"
                      icon
                      v-on="on"
                    >
                      <v-icon color="primary" style="font-size: 14px;"
                        >mdi-pencil-outline</v-icon
                      >
                    </v-btn>
                  </template>
                  <span class="ft font-weight-medium font-size-sm"
                    >Edit Branch</span
                  >
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      icon
                      v-on="on"
                      :to="{
                        name: 'master.branches.meter-readings',
                        query: { branchId: item.id }
                      }"
                    >
                      <v-icon color="primary" style="font-size: 14px;"
                        >mdi-lotion-outline</v-icon
                      >
                    </v-btn>
                  </template>
                  <span class="ft font-weight-medium font-size-sm"
                    >Reset Pump Meter Readings</span
                  >
                </v-tooltip>
              </template>
            </v-data-table>
          </div>
        </template>
      </v-col></v-row
    >
    <SnackBar
      :showSnackBar="showSnackBar"
      :message="message"
      :status="status"
    />

    <AddBranchAdminDialog
      :state="dialog('add')"
      :loading="loading"
      @branchAdmin="addBranchAdmin"
      @actions="close"
    />
    <EditBranchDialog
      :regions="regions"
      :loading="loading"
      :state="dialog('edit')"
      @branch="editBranch"
      @actions="close"
    />

    <AddBranchDialog
      :regions="regions"
      :state="dialog('details')"
      :loading="loading"
      @actions="close"
      @branch="addBranch"
    />
  </v-container>
</template>

<script>
import PageTitle from "../../../components/PageTitle";
import SnackBarMixins from "../../../mixins/SnackBarMixins";
import DialogMixins from "../../../mixins/DialogMixins";
import { mapGetters } from "vuex";
export default {
  name: "Managers",
  components: {
    PageTitle,
    AddBranchAdminDialog: () => import(`./dialogs/AddBranchAdmin`),
    AddBranchDialog: () => import(`./dialogs/AddBranchDialog`),
    EditBranchDialog: () => import(`./dialogs/EditBranchDialog`),
    SnackBar: () => import(`../../../components/SnackBar`)
  },
  data() {
    return {
      regions: [
        "Ahafo",
        "Ashanti",
        "Bono",
        "Bono East",
        "Central",
        "Eastern",
        "Greater Accra",
        "Northern",
        "North East",
        "Oti ",
        "Savannah",
        "Upper East",
        "Upper West",
        "Volta",
        "Western",
        "Western North"
      ],
      isMounted: false,
      options: {}
    };
  },
  mixins: [SnackBarMixins, DialogMixins],
  computed: {
    ...mapGetters({
      listLoading: "getIsListLoading",
      loading: "getIsLoading",
      branches: "branches/getAllBranches",
      paginate: "branches/getPagination"
    }),
    headers() {
      return [
        {
          text: "",
          value: "avatar"
        },
        {
          text: "Name",
          align: "start",
          sortable: false,
          value: "name"
        },
        { text: "Location", value: "location" },
        { text: "Telephone", value: "telephoneNumber" },
        { text: "Region", value: "region" },
        { text: "Action", value: "actions", align: "center" }
      ];
    }
  },
  watch: {
    options: {
      handler(value) {
        if (this.isMounted) {
          const { page, itemsPerPage } = value;
          this.$store.dispatch("branches/list", {
            isPaginated: true,
            page,
            limit: itemsPerPage
          });
        }
      },
      deep: true
    }
  },

  created() {
    let payload = {
      page: this.options.page,
      limit: this.options.limit,
      isPaginated: true
    };
    this.$store.dispatch("branches/list", payload);
    this.$store.dispatch("branches/defaultRole", "site-manager");
  },
  mounted() {
    this.isMounted = true;
  },
  filters: {
    firstLetterFunction(value) {
      return value.charAt(0).toUpperCase();
    }
  },

  methods: {
    addBranch(payload) {
      this.$store.dispatch("branches/create", payload);
    },
    editBranch(payload) {
      this.$store.dispatch("branches/update", payload);
    },
    addBranchAdmin(user) {
      let payload = {
        ...user,
        userType: "site-manager",
        branchId: this.$store.getters["branches/getBranchDetails"]?.id
      };
      this.$store.dispatch("branches/addBranchAdmin", payload);
    }
  }
};
</script>

<style scoped>
.button {
  background-color: #04a9f3; /* Green */
  border: none;
  color: white;
  padding: 1px 4px;
  border-radius: 3px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  margin-right: 40px;
  cursor: pointer;
}
#result .v-btn {
  min-width: 65px;
  width: 40px;
}
/* removes grey hover effect */
#result .v-btn::before {
  background-color: transparent;
}

/* adds icon scale hover effect */
#result .v-btn i:hover {
  transform: scale(1.15);
}

.buttonText {
  display: flex;
  flex-direction: row;
}
</style>
